import "./App.css";
import axios from "axios";
import { useEffect, useState } from "react";

function App() {
  const [listOfPosts, setListOfPosts] = useState([]);

  useEffect(() => {
    axios.get("http://localhost:3001/posts").then((response) => {
      setListOfPosts(response.data);
      //console.log(response.data);
    });
  }, []); //merge o data cand se face refresh
  return (
    <div className="App">
      <table className="table">
        <thead>
          <tr>
            <th scope="col">Title</th>
            <th scope="col">Post text</th>
            <th scope="col">Username</th>
          </tr>
        </thead>
        <tbody>
          {listOfPosts.map((item) => {
            return (
              <>
                <tr key={item.Id}>
                  <td>{item.title}</td>
                  <td>{item.postText}</td>
                  <td>{item.username}</td>
                </tr>
              </>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default App;
